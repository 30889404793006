<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="space-between">
        <h4>{{ $route.name }}</h4>
        <el-button
          type="primary"
          icon="el-icon-plus"
          size="medium"
          @click="openReceiptModal(null)"
        ></el-button>
      </el-row>
    </template>
    <el-table
      stripe
      :cell-style="() => 'text-align:center;'"
      :data="Receipts"
      style="width: 100%; z-index: 0"
    >
      <el-table-column prop="ref" label="Nº"> </el-table-column>
      <el-table-column prop="public_sell.name" label="licitação">
      </el-table-column>
      <el-table-column prop="route.name" label="rota"></el-table-column>
      <el-table-column label="pedidos">
        <template #default="{ row: { orders } }">
          <el-row
            v-for="order in orders"
            :key="order?.uid"
            type="flex"
            justify="center"
          >
            {{ order.code }}
          </el-row>
        </template>
      </el-table-column>
      <el-table-column prop="emitted_at" label="emissão">
        <template #default="{ row: { emitted_at } }">
          {{ dateFormatter.format(new Date(emitted_at)) }}
        </template>
      </el-table-column>
      <el-table-column label="ações" :min-width="80">
        <template #default="{ row: receipt }">
          <el-row type="flex" justify="end">
            <el-button-group>
              <el-button
                onlyIcon
                type="primary"
                circle
                @click="() => handleReceiptDetailsPageClickButton(receipt)"
              >
                <i class="el-icon-top-right"></i
              ></el-button>
              <el-button
                onlyIcon
                type="primary"
                circle
                @click="openReceiptModal(receipt)"
              >
                <i class="el-icon-edit"></i
              ></el-button>
              <el-popconfirm
                @confirm="deleteReceipt(receipt)"
                title="
              Remover recibo?
            "
                ><template #reference>
                  <el-button onlyIcon type="danger" circle>
                    <i class="el-icon-delete"></i></el-button></template
              ></el-popconfirm>
            </el-button-group>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <receipt-modal
      :showModal="showReceiptModal"
      :receipt="receipt"
      @close-modal="showReceiptModal = false"
      @should-update="fetchReceipts"
    ></receipt-modal>
  </el-card>
</template>

<script>
import ReceiptModal from "./modals/ReceiptModal.vue";
import { notifySuccess, notifyError } from "../utils/notifiers";
import { dateFormatter } from "../utils/formatters";
import ReceiptService from "../services/receipts";

export default {
  name: "ReceiptsPage",
  components: { ReceiptModal },
  data: () => ({
    hasError: false,
    isLoading: false,
    receipts: [],
    receipt: null,
    dateFormatter,
    showReceiptModal: false,
  }),
  mounted() {
    this.fetchReceipts();
  },
  computed: {
    Receipts() {
      return this.receipts?.data || [];
    },
  },
  methods: {
    async deleteReceipt(receipt) {
      if (receipt?.uid) {
        const { receipt, error } = await ReceiptService(receipt.uid).delete();

        if (receipt) {
          notifySuccess(receipt.message);

          this.fetchReceipts();
        } else {
          notifyError(error.message);
        }
      }
    },
    handleReceiptDetailsPageClickButton(receipt) {
      if (receipt) this.$router.push(`/licitacoes/recibos/${receipt.uid}`);
    },
    openReceiptModal(e) {
      (this.receipt = e), (this.showReceiptModal = true);
    },
    async updatePacking(receipt) {
      if (receipt) {
        const { receipt, error } = await ReceiptService(receipt.uid).update(
          receipt
        );

        if (receipt) {
          notifySuccess(receipt.message);
          this.fetchReceipts();
        } else {
          notifyError(error.message);
        }
      }
    },
    async fetchReceipts() {
      this.isLoading = true;
      const { receipts, error } = await ReceiptService().index();

      if (receipts) {
        this.receipts = receipts;
      } else {
        this.hasError = true;
        notifyError(error.message);
      }

      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
.el-card {
  width: 100%;
}
tr.unavailable {
  background-color: #fffde7;
}
tr.problem {
  background-color: #fbe9e7;
}
</style>
